.form-container {

    width: 50%;
    //margin: 10% 25%;
    border-radius: 4px;
    box-shadow: rgba(187,187,187,0.5) 1px 0px 1px 2px !important;

}

input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.submitbtn {
    /*background:linear-gradient(45deg,  transparent 0%, transparent 57%, #423246 100%),
               linear-gradient(-45deg, #8f5046 0%,  #A14436 0.3%, #4C2556 112%,  transparent 11% ),
               linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 20% );*/
   background:#283d52;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    position: relative;
}

button:hover {
    opacity: 0.8;
}

.cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
}
.codereview_title{
    height: auto;
    width: 50%;
    
    
}
 .codereview_title img{
 padding:10% 5%;
 }
.imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
}

img.avatar {
    width: 40%;
    border-radius: 50%;
}

.container {
    padding: 16px;
}

span.psw {
    float: right;
    padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .cancelbtn {
    width: 100%;
  }
}
body {
    margin: 0;
    font-family: Charlie Text,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Droid Sans,Helvetica Neue,sans-serif;
                /*-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                 "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
}


.nodev {
	  border-left: 1px black solid;
    list-style: none;
}
.folder{
    color: #4caf50;
    font-size: 30px;
    background: beige;

}
/* login loading gif */
.login-load {
    width: 16px;
    position: absolute;
    margin-left: 7px;
    
}
.uname,.pwd {
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
}
.login-body {
    //background: url("/home/raghu/lienion-code-review/src/images/infanion.jpeg");
    display: flex;
    //border: 1px solid black;
   margin: 4% 8%;
   padding: 5%;
   background:#fff;
   border-radius: 4px;
   box-shadow: rgb(187,187,187) 1px 0px 1px 2px !important;
}
