.load-icon {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 27%;
}

.App-logo {
    width: 73%;
    margin-top:3%;
    font-size: 12px;
}

.tree-container {
    display: flex;
    width: 100%;
    margin: 0% 4% 0% 4%;
    height: 100%;
    overflow: hidden;
}

.tree-view {
    width: 30%;
    height: auto;
    margin: 3px 0px;
    background: #ffffff;
    position: relative;
    border: 1px solid #d4d5d7;
    box-shadow: 1px 0px 3px #d4d5d7;
}
.tree-info {
    display: flex;
    font-size: 12px;
    font-weight: lighter;
    float: left;
    width: 100%;
    padding: 5px;
}

.tree-info-li {
    margin: 5px;
}
.tree_list_div{
   overflow: auto;
   height: 82vh;
   position: relative !important;
   overflow-x: hidden;
   scrollbar-color: #ff000091 #cacaca75;
   scrollbar-width: thin;
 }

.initial-ul {
    padding: 2% 3%;
    margin: 0%;
    margin: 0px 0px;
}

.list-ele-tree {
    display: flex;
}

.file-content {
    width: 60%;
    height: auto;
    margin: 2px 4px;
}

.folder_icon {
    color: #f2cc25;
    font-size: 15px;
}

.file_icon {
    font-size: 14px !important;
}

.file-btn {
    background: none;
    border: none;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
    cursor: pointer;
    margin: 5px -4px;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.2em;
}

.added {
    color: green;
}

.modified {
    color: blue;
}

.removed {
    color: red;
}

.file-tree {
    /* border: 2px solid black; */
    height: 640px;
    margin: 3px 5px;
    overflow-x: auto;
    overflow-y: auto;
    background: #e1dede5e;
}

.left-div {
    width: 40%;
}

.right-div {
    width: 750px;
    height: 650px;
    border: 2px solid grey;
    overflow-y: auto;
}

.cmnt {
    display: none !important; 
    border-radius: 50%;
    padding: 3px;
    font-size: 12px;
    border: 2px solid #3900d700;
    margin: 2px;
}
.p-cmnt:hover .cmnt {
    background: #fff;
    display: inline !important;
    cursor: pointer !important;
    color:cadetblue;
}
.list-area:hover {
    background: gainsboro;
    display: flex;
    width: 100%;
}

.list-ele {
    display: flex;
    width: 100%;
    position:relative;
    overflow-wrap: break-word !important;
}

.right-div ul {
    border: 1px solid gray;
    padding: 0%;
}

.list-left {
    width: 4%;
}

.li-center {
    width: 6%;
    display: flex;
    padding: 1% 0%;
}

.list-right {
    width: 85%;
    font-weight: lighter;
    /*padding: 4px 0px 4px 5px;*/
    /*--change font family --*/
    font-family : Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
}

.popup-content {
    width: 2434% !important;
    height: auto !important;
    margin: 103% 0% 15% 17%;
    position: -webkit-sticky !important;
    position: sticky !important;
    border-radius: 10px;
    box-shadow: rgba(79, 166, 166, 0.7) 2px 2px 5px 2px  !important; 
}
.popup-arrow {
    display:none
}
.txt-area {
    width: auto;
    border-radius: 4px !important;
    margin: 10px 5px;
}

.Todo_list {
    height: 40%;
    border: 1px solid grey;
    overflow-y: auto;
}

.review-section {
    height: 190px;
    overflow-y: auto;
    margin: 1px;
    overflow-x: auto;
    background: #eeee9870;
}

.left-div {
    width: 40%;
}

.right-div {
    width: 750px;
    height: 650px;
    border: 2px solid grey;
    overflow-y: auto;
}

.file-sec {
    background: #ffffff;
    height: 82vh;
    overflow-x: auto;
    z-index: 9;
    margin-bottom: 5px;
    border: 1px solid #d4d5d7;
    box-shadow: 1px 0px 3px #d4d5d7;
    width: 100%;
    scrollbar-color: #ff000091 #cacaca75;
    scrollbar-width: thin;
}
.file-sec-overlay {
    background: #7b7b7b96 !important;
    position: absolute;
    height: inherit;
    width: 100%;
    display: block;
    z-index: 999;
}

 .Review_start {
    top: 40%;
    left: 47%;
    position: absolute !important;
    background: #4f5051cc !important;
    color: #fff !important;
    padding: 15px 30px !important;
    border-radius: 10px;
    font-size: 30px !important;
    border: none !important;
}
.Review_start:hover{
  background:#9d159d94;
  color:white;
  border:4px solid beige; 
 }
.file_header {
    width: 100%;
    display: flex;
    background: #ffffff;
    border: 1px solid #d4d5d7;
    padding: 5px 0px;
}
 .cmnt_line_header {
    width: 100%;
    display: flex;
   
}
.dp-icon{
background: #ccd9d5;
border-radius: 50%;
}
.list-area:hover {
    background: gainsboro;
    display: flex;
    width: 100%
}

.list-ele {
    display: flex;
    width: 100%;
    position: relative;
}

.right-div ul {
    border: 1px solid gray;
    padding: 0%
}

.list-left {
    width: 4%;
    background: #d2caca40;
}
.dot_icon{
    width:2%;
}
.li-center {
    width: 8%;
    display: flex;
    font-size: 12px;
    background: #d2caca40;
    font-weight: bold;
}

.list-right {
    width: 85%;
    font-weight: lighter;
    padding-left:5px;
}

.txt-area {
    width: auto;
}

.Todo_list {
    height: 40%;
    border: 1px solid grey;
    overflow-y: auto;
}

.src {
    height: 60%;
    border: 1px solid grey;
    overflow-y: auto;
    background: ghostwhite;
    border-radius: 4px;
}

.review {
    display: flex;
    height: 30px;
    background: #f4f5f7;
    padding: 1% 1%;
    width: 98%;
    position: relative;
    border-bottom: 1px solid #6060603d;
}
.cmnt-close {
    right: 10px;
    position: absolute;
}
.close-btn {
    background-color:#fff;
    color:hsl(0, 100%, 50%);
    border-radius: 50%;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 7px;
    margin: 5% 0%;
    cursor: pointer;
    border: 1px solid #68686863;
    height: 25px;
    box-shadow: #a8a4a463 0px 0px 2px 0px;
}
 
 
.review-hdng {
    width: 82%;
    font-feature-settings: "smcp","c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc", "smcp", "c2sc";
    font-variant: all-small-caps;
    font-weight: bold;
}
.cmnt-top {
    float: left;
    width: 9%;
    margin-top: 3px;
}
.msg_load {
    width: 39px;
    height: 30px;
}
.file-frame {
    background: honeydew;
    height: 410px;
    overflow-y: auto;
}


.header-left {
    width: 40%;
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
    padding: 0% 2%;
    align-items:center;
}

.header-right-comment {
    width: 35%; 
    display: flex;
    align-items:center;
}

.header-right-resolve {
    width: 25%;
    display: flex;
    font-size: 15px;
    float: left;
    align-items:center;
}
.btn_space{
  margin:5%;
 }
.header-left:h3{
  margin: 5% 0%;
 }
.btn-class {
    border: none;
    background: none;
}

.list-folder {
    color: #242424cf;
    font-weight: lighter;
}

.display ul li {
    color: maroon;
}

.comment_submit, .comment_resolve {
    float: right;
    margin: 5px;
}

.display {
    height: 230px;
    margin: 1px;
    overflow-x: auto;
    overflow-y: auto;
    background: #c6ffb85e;
}

.added-line {
    background: #B4EEDB;
}

.removed-line {
    background: #FAC5C2;
}

.reviewicons {
    padding: 5px;
    font-size: 15px;
    background: none;
    border: none;
}

.cmnt-edit {
    display: flex;
    padding: 10px 0px 5px 0px;
    border-bottom: 1px solid #c8cbcd7d;
    background: #fff;
    box-shadow: #6b6a6a7a 1px 2px 4px 1px;
}

.dot {
    font-size: 10px !important;
    float: right;
    margin: 4px 0px !important;
    color: #ff0606b5;
    text-align: center !important;
}

.popup {
    display: block;
    background-color: white !important;
    width: 750px;
    margin: 0% 40%;
    margin-top: 30px;
    margin-bottom: 5px;
    height: 250px;
    overflow: auto
}

.comment-popup {
    display: flex;
    width: 100%;
    background: #86837edb;
}

.comment_header {
    width: 90%;
    font-weight: lighter;
    padding: 4px;
}

.comment_textarea {
    margin: 12px 12px;
}


.comment_buttons {
    float: right;
    width: 190px;
}

;
.comment_status {
    width: 30%;
}

.comments_for_line {
    background: #fff;
    margin: 1% 1%;
    padding: 0% 1%;
    border-radius: 7px;  
    border: 1px solid beige;
    box-shadow: #efefbf 0px 1px 5px 1px;

}

.comment_time {
    font-size: 11px;
    color: #2b2b2b;
    margin-top: 3px;
    margin-bottom: 3px;
    border: none !important;
    font-weight: bold;
    box-shadow: none !important;
}
.comment_div_bottom {
    width: 78%;
}

.comment_reg p {
    margin: 0% 0%;
    /*margin-top: 10px;*/
    word-wrap: break-word;
    width: 80%;
}

.user_comment_icon {
    font-size: 28px !important;
    margin: 8px 10px;
    padding: 9px 11px;
}

.comment_format_options {
    margin: 0px 10px;
    font-size: 16px !important;
    cursor: pointer;
    color: #000000bf;
    padding: 10px;
    border-radius: 50px;
    border: none;
    background: #9acebd82;
}

.comment_content {
    width: 91%;
    display: block;
    background: #fff;
}

.hidden {
    display: none;
}


.highlight {
    font-weight: bold !important;
}

.comment_format_options.comment_submit.fa.fa-times {
    margin-top: 0%;
}

.comment_format_options.comment_submit.fa.fa-pencil {
    margin-top: 0%;
}
.cmnt_state {
    width: 13%;
}
.review #color{	
    padding: 5px;
    border-radius: 6px;
    color: #fff;
    height: -moz-available;
    font-size: 12px;
    margin-top: 3px;
    font-weight: bold;
    margin-right: 10px;
    text-align: center;
}
.resolved {
    background: #1D7B06;
}
.un-resolved {
    background: #BF0808;
}
.no-comments {
    visibility:hidden;
    width: 9.5%;
}
.popup-overlay {
    position: absolute !important;
    background: #40e0d02e !importfant;
}

.comment_box_end {
    text-align: end;
    margin: 5px 15px;
}

.comment_box_buttons {
    text-align: end;
    margin: 5px 10px;
}

.file_load img {
    width: 20%;
    height: auto;
}

.file_load {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: fixed;
    width:10%;
    height:auto;
}
/*.user-icon{
    border-radius: 50%;
    background: #017e4f7a;
    border: cadetblue;
    margin: 1% 2% 0% 2%;
    width: 55px;
    height: 40px;
    color: black;
    cursor: pointer;
}*/
.user-icon{
    background: white; 
    margin: 1% 2% 1% 2%;
    width: 55px;
    height: 30px;
    color:blue;
    cursor: pointer;
    border: 1px solid #0000001c;
    box-shadow:#00c6c645 0px 0px 2px 1px;
}
.popup_comment_btn, .popup_resolve_btn, .comment_reopen {  
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 10px;
    cursor: pointer;
    border: none;
    font-weight: bold;
}

 .popup_comment_btn {
    background-color: #168f48;
    border-color: #168f48;
    border: none;
}
.popup_resolve_btn {
    background-color: #7964e6;
    border-color: #7964e6;
    margin-left: 2px;
    width: 85px;
}
.comment_reopen {
    background-color: #d94949;
    border-color: #d94949;
    margin-left: 2px;
    width: 85px;
}
.edit_btn {
    background-color: #026a91;
    color: #fff;
    border-radius: 4px;
    border-color: #026a91;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 10px;
    margin-left: 2px;
    cursor: pointer;
    width: 85px;
}
.fa.logout.comment_box_buttons {
    background-color: #fff;
    color:hsl(0, 100%, 50%);
    border-radius: 50%;
    font-size: 13px;
    font-weight: 400;
    padding: 8px 9px;
    cursor: pointer;
    border: 1px solid #80808063;
    height: 31px;
    margin-left: 34%;
    box-shadow: #80808012 0px 0px 0px 2px;
}
.stop_review_alert{
    position: absolute;
    height: auto;
    width: 575px;
    top: 30%;
    left: 30%;
    background: white;
    z-index: 999;
    border-radius: 10px;
    //text-align: center;
    display:none;
    box-shadow: rgba(35, 36, 36, 0.7) 2px 2px 5px 2px !important;
    padding: 2% 0%;
 }
.stop_review_alert_button{
   padding: 15px 25px;
   margin-right: 25px;
   border: none;
   border-radius: 4px;
   color: #000000c4;
}
.code_review_overlay{
position: absolute;
height: 100%;
width: 92%;
background: #fffdfd78;
z-index: 999;
display:none;
}
.comment_num{
text-align: center;
position: absolute;
padding: 0px 6px;
border-radius: 4px;
left: 85%;
//background: #22a822a3;
color: #262424ed;
font-size: 14px;
}
.comment_num_box {
    cursor: pointer;
    padding: 2px 6px;
    border-radius: 15px;
    margin: 3px;
    align-items: center;
    font-size: 12px;
}
.btn_font{
 font-size:25px;
 display:flex;
 }
.displayHide{display:none !important;}

.connection_status{
    height: 170px;
    position: absolute;
    width: 27%;
    left: 49%;
    top: 37%;
    background: white;
    z-index: 9999;
    border-radius: 15px;
    box-shadow: rgba(0,0,0,0.5) 5px 6px 3px 2px;
    text-align: center;
    display: none;
}
.conn_img{
    width: 102px;
    height: 89px;
}
.hold_overlay{
 position : relative;
 }
.custom_popup {
    height: auto !important;
    background:rgb(255, 255, 255) none repeat scroll 0% 0%;
    box-shadow:#3f403f91 0px 0px 2px 0px !important;
    margin: 5px 5px;
    padding-bottom: 5px;
    border: 1px solid #55535357;
}
.timer{
display:block;
text-align:center;
}
.hover_quill{
height: 135px;
position: absolute;
width: 100%;
background: #f6f3f338;
z-index: 9;
cursor: not-allowed;
}
.Quill_div{position:relative;}
.heading_font{font-size:15px !important;}

.timer_div{
    display: flex;
    align-items: center;
}
.comment_format_options.comment_submit {
    margin-top: 0%;
    background: #e5eaba;
}
#fileheader{
    box-shadow: #6b6a6a7a 1px 2px 4px 1px;
    margin: 1px 0%;
}
.ql-toolbar.ql-snow {
    box-sizing: border-box ;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    background: #d2f2ff !important;
    font-weight: bold !important;
    border: 1px solid #cccccc !important;
    border-radius: 6px 6px 0px 0px;
}
.ql-formats{
 float:right !important;
 }
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    border-radius: 0px 0px 6px 6px;
}
.file-load2{
    padding:18% 34%;
    width: 30%;
    height: auto;
}
.send_mail{
    font-size: 15px;
    margin: 0% 0% 0% 18%;
    color: grey;
}
.align_heading{
    text-align: center;
    margin: 1% 1% 1% 1%;
    font-size: 19px;	
}
.align_review_time {
    font-size: 15px;
    text-align: left;
    margin: 0% 0% 0% 19%;
    padding-bottom: 2%;
}
code{
  font-family: Helvetica,Arial;
}
.title_text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
    font-size: 15px;
}
pre{
  white-space: pre-wrap;
  width: 100%;
  font-size:12px;
  font-family : Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
  margin: 1% 0%;
 }
.folder-toggle{
    padding: 0%;
    margin: 1%;
    border: none;
    border-radius: 50%;
    width: 12px;
    color:gray;
}
.category{
    float: left;
    border: 1px solid #dee7f0;
    background: white;
    margin: 1% 2%;
    width: 100px;
}
.cat-icon{
    width: 12px;
    height: 12px;
    font-size: 10px;
    text-align: center;
    padding: 2px;
    color: white;
    margin: 0px 5px;
}