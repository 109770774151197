body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f5f7 !important;
  overflow:hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#top-header {
    border-bottom: 1px solid #d4d5d7;
    box-shadow: 1px 0px 3px #d4d5d7;
    left: 0;
    height: 65px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;
    background:#fff;
}
.control_icons {
    background: none repeat scroll 0 50% transparent;
    margin: 0 4%;
    position: absolute;
    width: 92%;
    z-index: 100;
}
.nav-logo {
    float: left;
    width: 15%;
}
a {
    color: #39c;
    text-decoration: none;
}
.nav-logo img {
    margin-top: 18px;
    width: 70%;
  
}
.page_content{
    position: absolute;
    width: 100%;
    top: 70px;
}
